:root {
  --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Lexend", sans-serif;
  --nav-font: "Poppins", sans-serif;
}
:root {
  --background-color: #ffffff;
  --default-color: #444444;
  --heading-color: #012970;
  --accent-color: #4154f1;
  --surface-color: #ffffff;
  --contrast-color: #ffffff;
}
:root {
  --nav-color: #012970;
  --nav-hover-color: #4154f1;
  --nav-mobile-background-color: #ffffff;
  --nav-dropdown-background-color: #ffffff;
  --nav-dropdown-color: #212529;
  --nav-dropdown-hover-color: #4154f1;
}

.light-background {
  --background-color: #f9f9f9;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

:root {
  scroll-behavior: smooth;
}

body {
  color: var(--default-color);
  font-family: "Lexend", sans-serif;
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}
p {
  color: #b6efca;
  font-family: "Lexend", sans-serif;
  font-size: 18px;
  font-weight: 300;
}
p:hover {
  color: #2ac687;
  font-family: "Lexend", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.container_bg {
  background-color: #000000;
  box-shadow: 2px 2px 2px 2px #2ac687;
}
.col_back {
  background-color: #fff;
  min-height: 800px;
  border-radius: 14px;
}
.col_bg {
  background-color: #000000;
  padding: 30px;
  margin: 60px 0 30px 0;
}
.isotope-container {
  padding: 2px;
}
.gamehubs {
  max-width: 200px;
}
.hot {
  max-width: 40px;
}
.header {
  color: var(--default-color);
  background-color: #2ac687;
  padding: 5px 0;
  transition: all 0.5s;
  z-index: 997;
  max-height: 67px;
}

.header .logo {
  line-height: 1;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: #b6efca;
}
.header .logo h1:hover {
  color: #ffffff;
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  color: var(--contrast-color);
  background: #249a69;
  font-size: 15px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 4px;
  transition: 0.3s;
  font-weight: 500;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: #249a69;
}

.back {
  background-image: url("./images/1.png");
  background-color: #dcf7e4;
}
.backShare {
  background-color: #000002;
  min-height: 880px;
}

@media (max-width: 1200px) {
  .header .logo {
    order: 1;
  }

  .header .btn-getstarted {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }

  .header .navmenu {
    order: 3;
  }
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

.index-page .header {
  --background-color: rgba(255, 255, 255, 0);
}

.index-page.scrolled .header {
  --background-color: #ffffff;
}

@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-color);
    padding: 18px 12px;
    font-size: 15px;
    font-family: var(--nav-font);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu li:last-child a {
    padding-right: 0;
  }

  .navmenu li:hover > a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover > a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    list-style: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mbile-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu > ul {
    display: block;
  }
}

@media (min-width: 1200px) {
  .navmenu .listing-dropdown {
    position: static;
  }

  .navmenu .listing-dropdown ul {
    margin: 0;
    padding: 10px;
    background: var(--nav-dropdown-background-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
  }

  .navmenu .listing-dropdown ul li {
    flex: 1;
  }

  .navmenu .listing-dropdown ul li a,
  .navmenu .listing-dropdown ul li:hover > a {
    padding: 10px 20px;
    font-size: 15px;
    color: var(--nav-dropdown-color);
    background-color: var(--nav-dropdown-background-color);
  }

  .navmenu .listing-dropdown ul li a:hover,
  .navmenu .listing-dropdown ul li .active,
  .navmenu .listing-dropdown ul li .active:hover {
    color: var(--nav-dropdown-hover-color);
    background-color: var(--nav-dropdown-background-color);
  }

  .navmenu .listing-dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
}

@media (max-width: 1199px) {
  .navmenu .listing-dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .listing-dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .listing-dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }
}

.footer {
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  padding-bottom: 50px;
  position: relative;
}

.footer .footer-newsletter {
  background-color: color-mix(in srgb, var(--accent-color), transparent 97%);
  border-top: 1px solid color-mix(in srgb, var(--accent-color), transparent 85%);
  border-bottom: 1px solid
    color-mix(in srgb, var(--accent-color), transparent 85%);
  padding: 50px 0;
}

.footer .footer-newsletter h4 {
  font-size: 24px;
}

.footer .footer-newsletter .newsletter-form {
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 6px 8px;
  position: relative;
  background-color: color-mix(
    in srgb,
    var(--background-color),
    transparent 50%
  );
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  transition: 0.3s;
  border-radius: 4px;
}

.footer .footer-newsletter .newsletter-form:focus-within {
  border-color: var(--accent-color);
}

.footer .footer-newsletter .newsletter-form input[type="email"] {
  border: 0;
  padding: 4px;
  width: 100%;
  background-color: color-mix(
    in srgb,
    var(--background-color),
    transparent 50%
  );
  color: var(--default-color);
}

.footer .footer-newsletter .newsletter-form input[type="email"]:focus-visible {
  outline: none;
}

.footer .footer-newsletter .newsletter-form input[type="submit"] {
  border: 0;
  font-size: 16px;
  padding: 0 20px;
  margin: -7px -8px -7px 0;
  background: var(--accent-color);
  color: var(--contrast-color);
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.footer .footer-newsletter .newsletter-form input[type="submit"]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.footer .footer-top {
  padding-top: 50px;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: color-mix(in srgb, var(--accent-color), transparent 97%);
  border: 1px solid color-mix(in srgb, var(--accent-color), transparent 85%);
  font-size: 16px;
  color: var(--accent-color);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: var(--contrast-color);
  background-color: var(--accent-color);
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  margin-right: 3px;
  font-size: 12px;
  line-height: 0;
  color: var(--accent-color);
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  display: inline-block;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: var(--accent-color);
}

.footer .footer-about a {
  color: var(--heading-color);
  font-size: 24px;
  font-weight: 600;
  font-family: var(--heading-font);
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .copyright {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 6px;
  font-size: 13px;
}

.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background-color: var(--accent-color);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

.page-title {
  color: var(--default-color);
  background-color: var(--background-color);
  position: relative;
}

.page-title .heading {
  padding: 80px 0;
  border-top: 1px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
}

.page-title .heading h1 {
  font-size: 38px;
  font-weight: 700;
}

.page-title nav {
  background-color: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 20px 0;
}

.page-title nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.page-title nav ol li + li {
  padding-left: 10px;
}

.page-title nav ol li + li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

section,
.section {
  color: var(--default-color);
  padding: 46px 0;
  scroll-margin-top: 98px;
  overflow: clip;
}

@media (max-width: 1199px) {
  section,
  .section {
    scroll-margin-top: 56px;
  }
}

.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}
.section-title-2 {
  text-align: center;
  position: relative;
}

.section-title-2 h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: color-mix(in srgb, var(--accent-color), transparent 90%);
  color: var(--accent-color);
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
  font-family: var(--default-font);
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: color-mix(in srgb, var(--accent-color), transparent 90%);
  color: #7c7c7f;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
  font-family: var(--default-font);
}
.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid var(--background-color);
  margin: 0 auto;
}
.section-title p {
  color: var(--heading-color);
  margin: 10px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  font-family: var(--heading-font);
}

.section-title p .description-title {
  color: var(--accent-color);
}

.values .card {
  background-color: var(--surface-color);
  color: var(--default-color);
  padding: 30px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: 0.3s;
  height: 100%;
  border: 0;
}

.values .card img {
  padding: 30px 50px;
  transition: 0.5s;
  transform: scale(1.1);
}

.values .card h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}

.values .card:hover {
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
}

.values .card:hover img {
  transform: scale(1);
}
.widgets-container {
  background-color: #000000;
  padding: 30px;
  margin: 60px 0 30px 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.widgets-container_tw {
  background-color: #eaeaea;
  padding: 13px;
  border-radius: 16px;
}

.widget-title {
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.widget-item {
  margin-bottom: 15px;
  cursor: pointer;
}

.widget-item:last-child {
  margin-bottom: 0;
}

.recent-posts-widget .post-item {
  display: flex;
  margin-bottom: 15px;
  border: 2px solid #b6efca;
  padding: 5px;
  border-radius: 20px;
}
.recent-posts-widget .post-item2 {
  display: flex;
  margin-bottom: 15px;
  padding: 5px;
  border-radius: 20px;
}

.recent-posts-widget .post-item:last-child {
  margin-bottom: 0;
}

.recent-posts-widget .post-item img {
  min-width: unset !important;
  height: 80px;
  width: 80px;
}
.left-sidebar .game_name {
  padding: 10px 8px 10px 16px;
}

.recent-posts-widget .post-item h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.recent-posts-widget .post-item h4 a {
  color: #fff;
  transition: 0.3s;
}

.recent-posts-widget .post-item h4 a:hover {
  color: #dcf7e4;
}

.recent-posts-widget .post-item time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}
.recent-posts-widget .post-item2 {
  display: flex;
  margin-bottom: 15px;
  padding: 5px;
  border-radius: 20px;
}

.recent-posts-widget .post-item2:last-child {
  margin-bottom: 0;
}

.recent-posts-widget .post-item2 img {
  margin-right: 10px;
  width: 60px;
  height: 60px;
  margin-top: 5px;
}

.recent-posts-widget .post-item2 h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.recent-posts-widget .post-item2 h4 a {
  color: #fff;
  transition: 0.3s;
}

.recent-posts-widget .post-item2 h4 a:hover {
  color: #dcf7e4;
}

.recent-posts-widget .post-item2 time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.tags-widget {
  margin-bottom: -10px;
}

.tags-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tags-widget ul li {
  display: inline-block;
}

.tags-widget ul a {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 60%);
  display: inline-block;
  transition: 0.3s;
}

.tags-widget ul a:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
  border: 1px solid var(--accent-color);
}

.tags-widget ul a span {
  padding-left: 5px;
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  font-size: 14px;
}
.cust_card {
  min-height: 300px;
}
.cust_background {
  background: #473f85;
}
.align-self-center {
  align-self: center !important;
}
.grid-box {
  width: 35px;
  text-align: center;
}
.update_info_row {
  width: 40%;
}
.update_info2_row {
  width: 80%;
}
.grid-border {
  border: 1px solid;
  margin-left: 1px;
  padding: 5px;
  margin-top: 5px;
}
.game-title {
  font-size: 12px;
  font-weight: 700;
}
.game-subtitle {
  font-size: 11px;
}
.border-box {
  border: 1px solid #b6efca;
  min-height: 300px;
}
.content-p {
  padding: 1.5rem;
}
.store {
  width: 35px;
  text-align: center;
  margin-top: -36px;
  float: right;
}
.emoji {
  width: 15px;
}

.img-fluid {
  width: 350px;
  height: auto;
  border: 1px solid white;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
img.img-fluid.image_size {
  width: inherit;
}
.img-fluid:hover {
  max-width: 100%;
  height: auto;
  border: 1px solid #dcf7e4;
  border-radius: 20px;
}
.img-fluid2:hover {
  max-width: 100%;
  height: auto;
  border: 2px solid #dcf7e4;
}
.rating {
  color: #ffb800;
  font-size: 18px;
}
li.nav-item.active {
  border: 3px solid #cbcecb;
  border-radius: 1px;
}
li.nav-item {
  border-bottom: 2px solid #cbcecb;
}
* {
  box-sizing: border-box;
}

.portfolio-content {
  position: relative;
  width: 100%;
}

.overlay {
  font-family: "Lexend", sans-serif;
  position: absolute;
  bottom: 0;
  background: rgb(12, 12, 12);
  background: rgba(3, 3, 3, 0.5);
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 5px;
}

.overlayShare {
  font-family: "Lexend", sans-serif;
  position: absolute;
  background: rgb(12, 12, 12);
  background: rgba(3, 3, 3, 0.5);
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 5px;
  min-height: 45px;
  top: 248px;
}
.overlayShare_ {
  font-family: "Lexend", sans-serif;
  position: absolute;
  background: rgb(12, 12, 12);
  background: rgba(3, 3, 3, 0.5);
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 5px;
  min-height: 45px;
  top: 137px;
}
.overlay_online_game {
  font-family: "Lexend", sans-serif;
  position: absolute;
  bottom: 0;
  background: rgb(12, 12, 12) !important;
  background: rgba(3, 3, 3, 0.5) !important;
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 10px;
  text-align: center;
  padding: 2px;
  border-radius: 12px;
}
.portfolio-content:hover .overlay_online_game {
  opacity: 1;
}
.portfolio-content:hover .overlayShare {
  opacity: 1;
}
.portfolio-content:hover .overlayShare_ {
  opacity: 1;
}
.portfolio-content:hover .overlay {
  opacity: 1;
}
.main-title {
  text-align: center;
  font-family: "Lexend", sans-serif;
  font-size: 23px;
}
.iframe-border {
  border: 5px solid #b6efca;
  background: #b6ebbd;
}
.swiper-horizontal {
  touch-action: pan-y;
  border: 5px solid #b6efca;
}
.text-color {
  color: #fff;
}
.text-color:hover {
  color: #b6efca;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  color: #b6efca !important;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  color: #b6efca !important;
}

.app_logo {
  font-size: 28px;
  font-weight: bold;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #ffffff, #f9f9f9);
  background-clip: text;
  -webkit-background-clip: text;
  width: fit-content;
  -webkit-text-stroke: 1px #333;
  position: relative;
}

.app_logo::before {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(47deg, #230b03, #b3b3b3);
  background-clip: text;
  -webkit-background-clip: text;
  transition: 0.5s;
  width: 0;
  overflow: hidden;
}

.app_logo:hover::before {
  width: 100%;
}
.font_size {
  font-size: 30px;
}
.popup-overlay_pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
@media only screen and (max-width: 320px) and (min-width: 370px) {
  .games_list .col-lg-1.portfolio-item img.img-fluid {
    width: 94px;
    max-width: 94px;
  }
}
@media only screen and (max-width: 767px) {
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 40%;
  }
  .col-md-2.mt-2.mb-2 {
    display: inline-flex;
    margin: 5px;
    padding: 0;
    flex-wrap: nowrap !important;
    width: max-content;
  }
  .games_list .container-fluid {
    justify-content: center;
  }
  section.portfolio.games_list {
    margin-top: 20px;
  }
  .games_list .col-lg-1.portfolio-item {
    display: inline-flex;
    margin: 5px;
    padding: 0;
    flex-wrap: nowrap !important;
    width: max-content;
  }
  .games_list a.portfolio-content.h-100 {
    display: block;
  }
  .games_list .col-lg-1.portfolio-item img.img-fluid {
    width: 110px;
    max-width: 110px;
  }
  .overlayShare {
    font-family: "Lexend", sans-serif;
    position: absolute;
    background: rgb(12, 12, 12);
    background: rgba(3, 3, 3, 0.5);
    color: #f1f1f1;
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 14px;
    text-align: center;
    padding: 5px;
    min-height: 45px;
    top: 66px;
  }
}
@media only screen and (max-width: 1220px) and (min-width: 1024px) {
  .game_images {
    min-width: 50px !important;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
  }
  .overlayShare {
    font-family: "Lexend", sans-serif;
    position: absolute;
    background: rgb(12, 12, 12);
    background: rgba(3, 3, 3, 0.5);
    color: #f1f1f1;
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 29px;
    text-align: center;
    padding: 3px;
    min-height: 45px;
    margin-top: 390px;
  }
  .recent-posts-widget .post-item h4 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .rating {
    color: #ffb800;
    font-size: 11px;
  }
  .game_main-title {
    float: none !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 768px) {
  .recent-posts-widget .post-item img {
    min-width: unset !important;
    height: 60px;
    width: 60px;
}
.recent-posts-widget .post-item h4 {
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 5px;
}
.rating {
  color: #ffb800;
  font-size: 12px;
}
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .popup-content_pop {
    background: #fff;
    border-radius: 8px;
    height: 360px;
    padding: 5px;
    position: relative;
    text-align: center;
    width: 50%;
    z-index: 99;
  }
  .qr_display {
    background-color: #eaeaea;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 3081px) and (min-width: 767px) {
  .popup-content_pop {
    background: #fff;
    border-radius: 8px;
    padding: 5px;
    position: relative;
    text-align: center;
    width: 96%;
    z-index: 99;
    height: 360px;
    max-width: 700px;
  }
  .qr_display {
    background-color: #eaeaea;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .popup-content_pop {
    background: #fff;
    border-radius: 8px;
    height: 70%;
    padding: 50px;
    position: relative;
    text-align: center;
    width: -webkit-fill-available;
  }
  .qr_display {
    background-color: #eaeaea;
  }
}

.blurred {
  filter: blur(5px);
}

.p_tag {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
}
.p_content {
  color: black;
  font-size: 18px;
}
.add_button {
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  border: 0;
  background-color: transparent;
}

.add_over_lay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.add_get_content {
  background: #ffffff;
  padding: 45px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  width: 90%;
  height: 40%;
}
@media only screen and (max-width: 776px) and (min-width: 518px) {
  .game_main-title {
    float: none;
  }
}
@media only screen and (max-width: 3081px) and (min-width: 750px) {
  .add_get_content {
    background: #ffffff;
    padding: 50px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    width: 40%;
    height: 30%;
  }
  .p_content {
    color: black;
    font-size: 15px;
  }
}
.add_shows {
  color: #6063af;
  text-align: center;
  padding: 5px;
  min-height: 380px;
}
.add_shows_main {
  border-radius: 20px;
  text-align: center;
  padding: 5px;
  min-height: 300px;
  position: absolute;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-ad {
  max-width: 100%;
  max-height: 350px;
  background-color: #2e2e32;
  border-radius: 4px;
  animation: pulse 1.5 infinite;
  -webkit-animation: pulse 1.5 infinite;
}

@keyframes pulse {
  0% {
    background-color: #2e2e32;
    transform: scale(1);
  }
  50% {
    background-color: #3a3a3f;
    transform: scale(1.02);
  }
  100% {
    background-color: #2e2e32;
    transform: scale(1);
  }
}

.p_tg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Advertisement {
  color: #000000;
}
.Advertisement_main {
  color: #fcfafa;
}
.text-color {
  color: #fff;
}
.main_error {
  background-color: #000000 !important;
  color: #ffffff !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.overlay_fontSize {
  font-size: 10px !important;
}
.game_images {
  min-width: 110px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.game_images_2 {
  min-width: 70px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.game_name_2 {
  margin-top: 20px !important;
  font-size: 18px !important;
}
.game_main-title {
  float: left;
}
.game_main-title_2 {
  float: right !important;
}
.option_color {
  cursor: pointer;
  background-color: DeepSkyBlue;
}
.add_h {
  min-height: 300px !important;
}
.game_images_3 {
  width: 40px !important;
  height: 40px !important;
}
.game_des {
  white-space: pre-line !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}
.p_tag_2 {
  color: black !important;
  font-size: 15px !important;
}
.game_images_4 {
  min-width: 50px !important;
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}
.app_store {
  margin-top: 13px !important;
  margin-left: 13px;
}
.online_game {
  text-align: center !important;
}
.share_section {
  justify-content: center !important;
  margin-top: 1px !important;
}
@media only screen and (max-width: 750px) and (min-width: 320px) {
  .col-md-2.mt-2.mb-2 {
    width: 115px;
    max-width: 115px;
  }
}

@media only screen and (max-width: 995px) and (min-width: 768px) {
  .col-lg-1.portfolio-item {
    width: 100px;
    min-width: 100px;
  }
  .overlayShare {
    color: white;
    margin-top: -32px;
    font-size: 11px;
  }
}
.cursor_point {
  cursor: pointer;
}
.back-to-top {
  position: fixed;
  border: 2px solid rgb(182 239 202);
  bottom: 85px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #2ac687;
  color: #ffffff;
  cursor: pointer;
  height: 50px;
  width: 50px;
}
.hot_ {
  padding: 10px;
}

@media only screen and (max-width: 399px) and (min-width: 320px) {
  .gamehubs {
    max-width: 170px;
  }
  .header .logo h1 {
    font-size: 30px;
    margin: 0;
    font-weight: 700;
    color: #b6efca;
    padding: 5px;
    width: 100px;
  }
  .hot {
    max-width: 35px;
  }
}
button.back-to-top {
  font-size: 25px;
}
.post_border {
  border: 1px solid #b4b7b4;
  background-color: #cbcecb;
}
.post_border:hover {
  background-color: #7c7f7c;
  color: #fff;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  position: absolute;
  border-radius: 15px;
}

.modal-qr {
  max-width: 100%;
  height: auto;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
}
img.modal-qr {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.pop_p {
  color: #000000;
}
.button_a {
  border: 1px solid #2ac687;
  border-radius: 5px;
}
.button_b {
  border: 1px solid #2ac687;
  border-radius: 5px;
  background-color: #bce3d3;
}
.footer {
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  padding-bottom: 50px;
  position: relative;
}

nav {
  text-align: center;
}
nav a {
  display: inline-block;
  font-size: 14px;
  margin: 0 30px;
  color: #fff;
  transition: all 0.3s;
}
.header_2 {
  color: var(--default-color);
  background-color: #2ac687;
  padding: 5px 0;
  transition: all 0.5s;
  z-index: 997;
  max-height: 80px;
}
.fafa {
  font-size: 10px;
}
.p_about_us {
  color: black !important;
  font-size: 18px !important;
  text-align: center;
}
.i_about_us {
  max-width: 25px;
}
.form-box {
  max-width: 100%;
  margin: auto;
  padding: 40px;
  background: #ffffff;
  border: 10px solid #f2f2f2;
}
.all_text_center {
  text-align: center;
}
.design_p_color {
  color: #2ac687;
}
.msg_border {
  border: 3px solid #babfb7;
}
.container_bg {
  background-color: #fff;
}
.copyright {
  color: rgb(255, 255, 255);
}
.copyright:hover {
  color: #ffffff;
}
nav a:hover {
  color: #000000;
}

.submit_c {
  background: linear-gradient(45deg, #2ac687, #b2cbc1) no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  padding: 11px 35px;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50px;
}

.input-main input {
  height: 26px;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: #a2a2a2;
  font-size: 1rem;
}

.input-main input::placeholder {
  color: #a2a2a2;
}

.input-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #ebebeb;
  border-radius: 100px;
  margin-right: 15px;
}

.input-main svg {
  width: 30px;
  height: auto;
  fill: #a2a2a2;
  margin-right: 10px;
}
.form-box_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  margin-top: 39px;
}

.description {
  font-size: 1.1rem;
  color: #636363;
  margin-top: 10px;
}

.title {
  text-transform: uppercase;
  font-weight: 900;
  color: #4a4a4a;
}

.img-head {
  width: 100%;
  height: auto;
  margin-top: 45px;
}

.container_c {
  background-color: white;
  text-align: center;
  width: 769px;
  height: 486px;
  border-radius: 50px;
  margin: 50px auto;
  margin-top: 8%;
}
@media only screen and (max-width: 500px) {
  .img-head {
    margin-top: 75px;
  }
  .form-box_2 {
    display: grid;
  }
  .input-main {
    margin-right: 0;
  }
  .submit {
    margin-top: 16px;
  }
  .description {
    font-size: 1rem;
    margin: 0 35px;
  }
  .title {
    font-size: 1.5rem;
  }
  .form-box_2 {
    margin-top: 25px;
  }

  .container_c {
    border-radius: 15px;
    width: 100%;
  }
}

@media only screen and (max-width: 430px) and (min-width: 320px) {
  .header_2 {
    color: var(--default-color);
    background-color: #2ac687;
    padding: 5px 0;
    transition: all 0.5s;
    z-index: 997;
    max-height: 105px;
  }
  .back-to-top {
    position: fixed;
    border: 2px solid rgb(182 239 202);
    bottom: 105px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #2ac687;
    color: #ffffff;
    cursor: pointer;
    height: 50px;
    width: 50px;
  }
  .col-md-6.icon-box {
    margin-top: 5px;
  }
}

.app_store_botton {
  background: linear-gradient(45deg, #2ac687, #b2cbc1) no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  padding: 7px 20px;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50px;
}

.app_store_botton_c {
  background: linear-gradient(45deg, #2ac687, #c3ddd3) no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  padding: 7px 20px;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50px;
}
.app_store_botton_c:hover {
  background: linear-gradient(45deg, #29cf8d, #9cdcc4) no-repeat;
}
b,
strong {
  font-weight: bolder;
  color: #2ac687;
}
svg.bi.bi-gear-fill {
  color: #2ac687;
}
svg.bi.bi-fire {
  color: #2ac687;
}
.cust_input {
  height: 50px;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: #a2a2a2;
  font-size: 1rem;
  border: 1px solid #a2a2a2;
  background: #fffdfd;
}

.contact .info-item {
  background: #f8f8f8;
  padding: 30px;
}

.contact .info-item i {
  font-size: 38px;
  line-height: 0;
  color: var(--accent-color);
}

.contact .info-item h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-item p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: color-mix(in srgb, var(--default-color), transparent 96%);
  padding: 30px;
  height: 100%;
}
.contact_back {
  background-color: #fff;
}
.contact_text_color {
  color: #7c7c7f;
}
.section_top {
  margin-top: 10%;
}
.sitename {
  color: #fff;
}
.form_center {
  text-align: center;
}

.red-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .col-lg-2 {
    flex: 0 0 auto;
    width: 130px;
  }
}

.col-md-6.icon-box .row {
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
}
.col-md-6.icon-box .row .update_info_row {
  width: 32px;
  padding: 0;
}
li.nav-item.active {
  border: 2px solid #cbcecb;
  border-radius: 6px 6px 0 0;
  background-color: #cbcecb;
}
.copyright,
.copyright:hover {
  color: #fff;
  background-color: #1ba76e;
  max-width: unset;
}
link:hover {
  color: #fff;
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 35%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 65%;
  }
}

.loader {
  color: #2ac687;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  font-size: 100px;
  border-radius: 50%;
  position: relative;
  left: 50%;
  top: 35%;
  transform: translateZ(0);
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
}

@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, 
    -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
     -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, 
     -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
     -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, 
     -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 
    0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
.load-more-button {
  display: block;
  width: 132px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #fff;
  background: #4C00EE;
  color: #fff;
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  margin: 10px auto;
  letter-spacing: 1px;
}

@media only screen and (max-width: 768px) and (min-width: 450px) {
.col-lg-9 {
  display: flex;
}
}
a.portfolio-content{
  display: block;
} 